import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const LogoutHandler = () => {
  const { logout } = useContext(AuthContext); // Access the logout function from AuthContext
  const navigate = useNavigate(); // Hook to programmatically navigate to another route

  useEffect(() => {
    // Call the logout function to clear user authentication data
    logout();
    // Redirect the user to the home page or login page after logging out
    navigate('/');
  }, [logout, navigate]); // Dependencies array to run the effect when logout or navigate changes

  return null; // No UI to render for this component
};

export default LogoutHandler;
