import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./Modal";
import "./PatientRecords.css";

const PatientRecords = () => {
  const [patients, setPatients] = useState([]); // State to store the list of patients
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const [newPatient, setNewPatient] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    dateOfBirth: "",
  }); // State to store new patient data
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  const [searchTerm, setSearchTerm] = useState(""); // State for the search input value
  const [lazyLoadCount, setLazyLoadCount] = useState(9); // State to control the number of loaded patients
  const isInitialFetch = useRef(true); // Ref to track the initial data fetch
  const [forceUpdate, setForceUpdate] = useState(0); // State to force component re-render

  useEffect(() => {
    // Fetch patients from the API
    const fetchPatients = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/patients`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token for authorization
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching patients: ${response.statusText}`);
        }

        const data = await response.json();
        data.sort((a, b) => b.patientId - a.patientId); // Sort patients by ID in descending order
        setPatients(data);
        if (isInitialFetch.current) {
          toast.success("Patient record fetched successfully!");
          isInitialFetch.current = false; // Show toast only on the first fetch
        }
      } catch (error) {
        console.error("Error fetching patients:", error);
        toast.error(`Error fetching patients: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, [forceUpdate]); // Re-run effect when forceUpdate changes

  // Handle input changes for the form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      if (sanitizedValue.length <= 10) {
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          [name]: sanitizedValue,
        }));
      }
    } else {
      setNewPatient((prevPatient) => ({
        ...prevPatient,
        [name]: value,
      }));
    }
  };

  // Handle adding a new patient
  const handleAddPatient = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newPatient.email)) {
      toast.error("Please enter a valid email address.");
      setLoading(false);
      return;
    }
    // Check if the phone number is less than 10 digits
    if (newPatient.phone.length < 10) {
      toast.error("Phone number should be of 10 digits");
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patients/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(newPatient), // Send new patient data
        }
      );

      const text = await response.text(); // Read the response as text

      if (!response.ok) {
        throw new Error(text); // Throw an error with the text response
      }

      let addedPatient;
      try {
        addedPatient = JSON.parse(text); // Try to parse the text as JSON

        setPatients((prevPatients) => {
          const updatedPatients = [addedPatient, ...prevPatients];
          updatedPatients.sort((a, b) => b.patientId - a.patientId);
          return updatedPatients;
        });
      } catch (err) {
        console.error("Response is not JSON:", text);
      }

      // Reset new patient state and close the modal
      setNewPatient({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        dateOfBirth: "",
      });
      setIsModalOpen(false);
      setForceUpdate(forceUpdate + 1); // Trigger re-render
      toast.success("Patient added successfully!");
    } catch (error) {
      console.error("Error adding patient:", error);
      toast.error(`Error adding patient: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Load more patients for infinite scrolling
  const loadMorePatients = () => {
    setLazyLoadCount((prevCount) => prevCount + 9);
  };

  // Filter patients based on the search term
  const filteredPatients = patients
    .filter((patient) =>
      `${patient.firstName} ${patient.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .slice(0, lazyLoadCount);

  return (
    <div className="patient-records">
      <h2>Patient Records</h2>
      <input
        type="text"
        placeholder="Search patients..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
      <button className="btn" onClick={() => setIsModalOpen(true)}>
        Add Patient
      </button>
      {loading ? (
        <div className="spinner">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />{" "}
          {/* Show loading spinner */}
        </div>
      ) : (
        <>
          <table className="patient-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Date of Birth</th>
              </tr>
            </thead>
            <tbody>
              {filteredPatients.map((patient) => (
                <tr key={patient.patientId}>
                  <td>{patient.patientId}</td>
                  <td>{patient.firstName}</td>
                  <td>{patient.lastName}</td>
                  <td>{patient.email}</td>
                  <td>{patient.phone}</td>
                  <td>{patient.address}</td>
                  <td>{new Date(patient.dateOfBirth).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {filteredPatients.length < patients.length && (
            <button className="load-more-button" onClick={loadMorePatients}>
              Load More
            </button>
          )}
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="patient-modal"
      >
        <h2 className="modal__header">Add New Patient</h2>
        <form className="modal__form" onSubmit={handleAddPatient}>
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={newPatient.firstName}
            onChange={handleInputChange}
            required
          />
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={newPatient.lastName}
            onChange={handleInputChange}
            required
          />
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={newPatient.email}
            onChange={handleInputChange}
            required
          />
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            value={newPatient.phone}
            onChange={handleInputChange}
            required
          />
          <label>Address</label>
          <input
            type="text"
            name="address"
            value={newPatient.address}
            onChange={handleInputChange}
            required
          />
          <label>Date of Birth</label>
          <input
            type="date"
            name="dateOfBirth"
            value={newPatient.dateOfBirth}
            onChange={handleInputChange}
            required
            max={new Date(Date.now() - 86400000).toISOString().split("T")[0]} // Set max date as yesterday
          />
          <button className="btn" type="submit" disabled={loading}>
            {loading ? <ClipLoader size={20} color={"#fff"} /> : "Add Patient"}
          </button>
        </form>
      </Modal>
      <ToastContainer /> {/* Container for displaying toast notifications */}
    </div>
  );
};

export default PatientRecords;
