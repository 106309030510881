import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, children, className }) => {
  // If the modal is not open, return null to render nothing
  if (!isOpen) return null;

  return (
    <>
      {/* Main modal container with dynamic class names */}
      <div className={`modal ${className}`}>
        {/* Close button to trigger the onClose callback */}
        <button className="btn--close-modal" onClick={onClose}>&times;</button>
        {/* Children elements to be displayed inside the modal */}
        {children}
      </div>
      {/* Overlay to darken the background and close the modal when clicked */}
      <div className="overlay" onClick={onClose}></div>
    </>
  );
};

export default Modal;
