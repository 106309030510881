import React, { createContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create a context for authentication
export const AuthContext = createContext(); // Ensure this is exported correctly

// AuthProvider component provides the authentication context to its children
export const AuthProvider = ({ children }) => {
  // State to manage authentication status, user details, and loading state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Ref to track if the logout toast has been shown
  const logoutToastShown = useRef(false); 

  // Effect hook to check local storage for authentication data on component mount
  useEffect(() => {
    // Retrieve stored data from local storage
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    const storedRole = localStorage.getItem('role');
    const storedUserId = localStorage.getItem('userId');

    // If all data is found, set the authenticated state and user details
    if (token && storedUsername && storedRole && storedUserId) {
      setIsAuthenticated(true);
      setUsername(storedUsername);
      setRole(storedRole);
      setUserId(storedUserId);
    } else {
      console.log("User not authenticated on reload");
    }

    // Set loading to false after checking local storage
    setLoading(false);
  }, []);

  // Function to handle user login
  const login = (username, token, role, userId) => {
    // Store user data in local storage
    localStorage.setItem('username', username);
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('userId', userId);

    // Update state to reflect user is authenticated
    setIsAuthenticated(true);
    setUsername(username);
    setRole(role);
    setUserId(userId);

    // Reset the ref to allow logout toast to show again
    logoutToastShown.current = false; 
  };

  // Function to handle user logout
  const logout = () => {
    // Remove user data from local storage
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');

    // Update state to reflect user is not authenticated
    setIsAuthenticated(false);
    setUsername('');
    setRole('');
    setUserId(null);

    // Show a toast notification if it hasn't been shown before
    if (!logoutToastShown.current) {
      toast.success("You have been logged out successfully.");
      logoutToastShown.current = true; // Prevent showing the toast again
    }
  };

  // Provide the authentication state and functions to children components
  return (
    <AuthContext.Provider value={{ isAuthenticated, username, role, userId, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
