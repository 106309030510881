import emailjs from "emailjs-com";
import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Importing Toastify CSS for styling notifications
import "./BillingRecords.css"; // Custom CSS for the BillingRecords component
import Modal from "./Modal"; // Modal component for adding new billing records

const BillingRecords = () => {
  // State to manage the list of bills
  const [bills, setBills] = useState([]);
  // State to control the visibility of the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  // State to manage the new bill data being added
  const [newBill, setNewBill] = useState({
    clinicId: "1",
    patientId: "",
    patientFirstName: "",
    patientLastName: "",
    patientEmail: "",
    amount: "",
    paymentMethod: "",
  });
  // State to manage the loading state of the component
  const [loading, setLoading] = useState(false);
  // State to manage the search term for filtering bills
  const [searchTerm, setSearchTerm] = useState("");
  // State to control the number of bills loaded on the screen
  const [lazyLoadCount, setLazyLoadCount] = useState(9);
  // State to manage the current bill being processed for email notifications
  const [currentBillSending, setCurrentBillSending] = useState(null);
  // State to store doctor's fees for setting default amount
  const [doctorFees, setDoctorFees] = useState("");
  // Ref to ensure the toast message is only shown once
  const toastShowRef = useRef(false);

  // useEffect hook to fetch bills data on component mount
  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true); // Set loading to true while the request is being made
      try {
        // Send a GET request to the API to fetch bills
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/billings`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token for authorization
            },
          }
        );

        // Check if the response is not OK and throw an error if so
        if (!response.ok) {
          throw new Error(`Error fetching bills: ${response.statusText}`);
        }

        // Parse the JSON data from the response
        const data = await response.json();
        // Update the state with the fetched bills
        setBills(data);
        // Show a success toast if this is the initial fetch
        if (!toastShowRef.current) {
          toast.success("Billing records fetched successfully!");
          toastShowRef.current = true; // Prevent the toast from showing again
        }
      } catch (error) {
        // Log the error and show an error toast
        console.error("Error fetching bills:", error);
        toast.error(`Error fetching bills: ${error.message}`);
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    fetchBills();
  }, []);

  // useEffect hook to fetch doctor's fees on component mount
  useEffect(() => {
    const fetchDoctorFees = async () => {
      try {
        // Send a GET request to the API to fetch doctor fees
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/clinics/doctor/1`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token for authorization
            },
          }
        );

        // Check if the response is not OK and throw an error if so
        if (!response.ok) {
          throw new Error(`Error fetching clinic info: ${response.statusText}`);
        }

        // Parse the JSON data from the response
        const data = await response.json();
        // Set the doctor's fees and update the amount in the new bill
        setDoctorFees(data[0].doctorFees);
        setNewBill((prevBill) => ({
          ...prevBill,
          amount: data[0].doctorFees,
        }));
      } catch (error) {
        console.error("Error fetching clinic info:", error);
      }
    };

    fetchDoctorFees();
  }, []);

  // Handle changes to the input fields in the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBill((prevBill) => ({
      ...prevBill,
      [name]: value,
    }));
  };

  // Handle the submission of the new bill form
  const handleAddBill = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true while the request is being made
    try {
      // Send a POST request to the API to add the new bill
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/billings/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token for authorization
          },
          body: JSON.stringify(newBill),
        }
      );

      setLoading(false);

      // Check if the response is not OK and throw an error if so
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error adding bill: ${errorText}`);
      }

      // Parse the JSON data from the response
      const data = await response.json();
      // Update the state with the new bill
      setBills((prevBills) => [...prevBills, data]);
      // Close the modal and reset the new bill form
      setIsModalOpen(false);
      setNewBill({
        clinicId: "1",
        patientId: "",
        patientFirstName: "",
        patientLastName: "",
        patientEmail: "",
        amount: doctorFees,
        paymentMethod: "",
      });
      toast.success("Bill added successfully!");
    } catch (error) {
      setLoading(false);
      console.error("Error adding bill:", error);
      toast.error(`${error.message}`);
    }
  };

  // Function to load more bills (lazy loading)
  const loadMoreBills = () => {
    setLazyLoadCount((prevCount) => prevCount + 9);
  };

  // Handle generating a PDF for a specific bill
  const handleGeneratePdf = async (billingId) => {
    try {
      // Send a request to the API to generate a PDF for the bill
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/billings/${billingId}/generatePdf`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token for authorization
          },
        }
      );

      // Check if the response is not OK and throw an error if so
      if (!response.ok) {
        throw new Error(`Error generating PDF: ${response.statusText}`);
      }

      // Create a blob from the response and generate a download link
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `bill_${billingId}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error(`Error generating PDF: ${error.message}`);
    }
  };

  // Handle sending an email notification for a specific bill
  const sendEmailNotification = (bill) => {
    setCurrentBillSending(bill.billingId);
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID1;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID1;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID1;

    // Generate the HTML content for the billing details email
    const generateBillingDetailsHTML = (bill) => {
      return `
        <table style="border-collapse: collapse; width: 100%;">
          <tr>
            <th style="border: 1px solid #d0d0d0;">Billing ID</th>
            <th style="border: 1px solid #d0d0d0;">Patient Name</th>
            <th style="border: 1px solid #d0d0d0;">Amount</th>
            <th style="border: 1px solid #d0d0d0;">Payment Method</th>
          </tr>
          <tr>
            <td style="border: 1px solid #d0d0d0;">${bill.billingId}</td>
            <td style="border: 1px solid #d0d0d0;">${bill.patientFirstName} ${bill.patientLastName}</td>
            <td style="border: 1px solid #d0d0d0;">${bill.amount}</td>
            <td style="border: 1px solid #d0d0d0;">${bill.paymentMethod}</td>
          </tr>
        </table>
      `;
    };

    // Define the email parameters
    const emailParams = {
      to_name: `${bill.patientFirstName} ${bill.patientLastName}`,
      to_email: bill.patientEmail,
      billing_details_html: generateBillingDetailsHTML(bill),
      billingId: bill.billingId,
      amount: bill.amount,
      paymentMethod: bill.paymentMethod,
      additional_notes: "Thank you for your payment.",
    };

    // Send the email using emailjs
    emailjs
      .send(serviceID, templateID, emailParams, userID)
      .then(
        (response) => {
          toast.success("Notification sent successfully!");
        },
        (error) => {
          console.error("Error sending email:", error);
          toast.error(`Error sending email: ${error.message}`);
        }
      )
      .finally(() => {
        setCurrentBillSending(null);
      });
  };

  // Filter bills based on search term and lazy load count
  const filteredBills = bills
    .filter(
      (bill) =>
        `${bill.patientId}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${bill.billingId}`.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, lazyLoadCount);

  return (
    <div className="billing-records">
      <h2>Billing Records</h2>
      <input
        type="text"
        placeholder="Search by Patient ID or Billing ID..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
      <button className="btn" onClick={() => setIsModalOpen(true)}>
        Add Bill
      </button>

      {loading ? (
        // Display a loading spinner if the data is being loaded
        <div className="spinner">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <>
          {/* Table displaying the filtered bills */}
          <table className="billing-table">
            <thead>
              <tr>
                <th>Billing ID</th>
                <th>Patient ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Payment Method</th>
                <th>Generate PDF</th>
                <th>Send Notification</th>
              </tr>
            </thead>
            <tbody>
              {filteredBills.map((bill) => (
                <tr key={bill.billingId}>
                  <td>{bill.billingId}</td>
                  <td>{bill.patientId}</td>
                  <td>{bill.patientFirstName}</td>
                  <td>{bill.patientLastName}</td>
                  <td>{bill.patientEmail}</td>
                  <td>{bill.amount}</td>
                  <td>{bill.paymentMethod}</td>
                  <td>
                    <button
                      className="pdf-button"
                      onClick={() => handleGeneratePdf(bill.billingId)}
                    >
                      Generate
                    </button>
                  </td>
                  <td>
                    <button
                      className="notification-button"
                      onClick={() => sendEmailNotification(bill)}
                      disabled={currentBillSending === bill.billingId}
                    >
                      {currentBillSending === bill.billingId ? (
                        <ClipLoader size={15} color={"#fff"} />
                      ) : (
                        "Share"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Button to load more bills if there are more */}
          {filteredBills.length < bills.length && (
            <button className="load-more-button" onClick={loadMoreBills}>
              Load More
            </button>
          )}
        </>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="billing-modal"
      >
        <h2 className="modal__header">Add New Bill</h2>
        <form className="modal__form" onSubmit={handleAddBill}>
          <label>Patient ID</label>
          <input
            type="text"
            name="patientId"
            value={newBill.patientId}
            onChange={handleInputChange}
            required
          />
          <label>Amount</label>
          <input type="text" name="amount" value={newBill.amount} readOnly />
          <label>Payment Method</label>
          <select
            name="paymentMethod"
            value={newBill.paymentMethod}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Payment Method</option>
            <option value="Prepaid">Prepaid</option>
            <option value="Cash">Cash</option>
            <option value="Online">Online</option>
          </select>
          <button className="btn" type="submit" disabled={loading}>
            {loading ? <ClipLoader size={20} color={"#fff"} /> : "Add Bill"}
          </button>
        </form>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default BillingRecords;
