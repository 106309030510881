import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importing Toastify CSS for styling notifications
import './App.css'; // Custom global styles for the app
import './ToastifyCustom.css'; // Custom styles specifically for Toastify notifications
import Dashboard from './components/Dashboard'; // Dashboard component
import DoctorDashboard from './components/DoctorDashboard'; // DoctorDashboard component
import LandingPage from './components/LandingPage'; // LandingPage component
import LogoutHandler from './components/LogoutHandler'; // Component to handle logout functionality
import RequireAuth from './components/RequireAuth'; // Higher-order component to protect routes
import { AuthProvider } from './context/AuthContext'; // AuthProvider for managing authentication context

function App() {
  return (
    // Provide authentication context to the entire app
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Route for the landing page */}
            <Route path="/" element={<LandingPage />} />
            
            {/* Protected route for the dashboard, only accessible if authenticated */}
            <Route path="/dashboard" element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            } />

            {/* Protected route for the doctor's dashboard */}
            <Route path="/doctordashboard" element={
              <RequireAuth>
                <DoctorDashboard />
              </RequireAuth>
            } />

            {/* Route for handling user logout */}
            <Route path="/logout" element={<LogoutHandler />} />
          </Routes>
          {/* Container for displaying toast notifications */}
          <ToastContainer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
