// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.appointment h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.filters {
  margin-bottom: 20px;
}

.filters input {
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
}

.appointment-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px;
}

.appointment-table th,
.appointment-table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
}

.appointment-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.appointment-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.appointment-table tr:hover {
  background-color: #f1f1f1;
}

.appointment-table th,
.appointment-table td {
  text-align: left;
  padding: 15px;
  font-size: 18px;
}

.appointment-table th {
  background-color: #f4f4f4;
  color: #444;
}





/* Load More Button Styling */
.load-more-button {
  background-color: #999;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #444;
}
`, "",{"version":3,"sources":["webpack://./src/components/Appointment.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,eAAe;AACjB;;AAEA;;EAEE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,gBAAgB;EAChB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;;;;;AAMA,6BAA6B;AAC7B;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".appointment {\n  padding: 20px;\n  font-family: Arial, sans-serif;\n}\n\n.appointment h2 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n\n.filters {\n  margin-bottom: 20px;\n}\n\n.filters input {\n  margin-right: 10px;\n  padding: 10px;\n  font-size: 16px;\n}\n\n.appointment-table {\n  width: 100%;\n  border-collapse: collapse;\n  font-size: 18px;\n}\n\n.appointment-table th,\n.appointment-table td {\n  border: 1px solid #ddd;\n  padding: 12px 15px;\n  text-align: left;\n}\n\n.appointment-table th {\n  background-color: #f4f4f4;\n  font-weight: bold;\n}\n\n.appointment-table tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.appointment-table tr:hover {\n  background-color: #f1f1f1;\n}\n\n.appointment-table th,\n.appointment-table td {\n  text-align: left;\n  padding: 15px;\n  font-size: 18px;\n}\n\n.appointment-table th {\n  background-color: #f4f4f4;\n  color: #444;\n}\n\n\n\n\n\n/* Load More Button Styling */\n.load-more-button {\n  background-color: #999;\n  color: #fff;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  font-size: 18px;\n  border-radius: 5px;\n  margin-top: 20px;\n  transition: background-color 0.3s ease;\n}\n\n.load-more-button:hover {\n  background-color: #444;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
