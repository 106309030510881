import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Importing Toastify CSS for styling notifications
import './Appointment.css'; // Custom CSS for the Appointment component

const Appointment = () => {
  // State to manage the list of appointments
  const [appointments, setAppointments] = useState([]);
  // State to manage the search term for filtering appointments by name
  const [searchTerm, setSearchTerm] = useState('');
  // State to manage the selected date for filtering appointments by date
  const [selectedDate, setSelectedDate] = useState('');
  // State to control the number of appointments loaded on the screen
  const [lazyLoadCount, setLazyLoadCount] = useState(9);
  // State to manage the loading state of the component
  const [loading, setLoading] = useState(false);
  // Ref to ensure the toast message is only shown once
  const toastShowRef = useRef(false);

  // useEffect hook to fetch appointments data on component mount
  useEffect(() => {
    fetchAppointments();
  }, []);

  // Function to fetch appointments from the API
  const fetchAppointments = async () => {
    setLoading(true); // Set loading to true while the request is being made
    try {
      // Send a GET request to the API to fetch appointments
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-appointments`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token for authorization
          'Content-Type': 'application/json'
        }
      });

      // Check if the response is not OK and throw an error if so
      if (!response.ok) {
        throw new Error(`Error fetching appointments: ${response.statusText}`);
      }

      // Parse the JSON data from the response
      const data = await response.json();
      // Update the state with the sorted appointments
      setAppointments(sortAppointments(data));
      // Show a success toast if this is the initial fetch
      if (!toastShowRef.current) {
        toast.success("Appointments data fetched successfully!");
        toastShowRef.current = true;  // Set it to true after showing toast
      }
    } catch (error) {
      // Log the error and show an error toast
      console.error('Error fetching appointments:', error);
      toast.error('Error fetching appointments.');
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  // Function to sort appointments by date and time
  const sortAppointments = (appointments) => {
    const today = new Date().toISOString().split('T')[0];

    const sortedAppointments = appointments.sort((a, b) => {
      // Sort by appointment date and then by time
      if (a.appointmentDate === b.appointmentDate) {
        return new Date(`1970-01-01T${a.appointmentTime}`) - new Date(`1970-01-01T${b.appointmentTime}`);
      }
      return new Date(a.appointmentDate) - new Date(b.appointmentDate);
    });

    // Filter appointments to only show those on or after today
    const todayAppointments = sortedAppointments.filter(appointment => appointment.appointmentDate >= today);

    return todayAppointments;
  };

  // Function to load more appointments (lazy loading)
  const loadMoreAppointments = () => {
    setLazyLoadCount(prevCount => prevCount + 9);
  };

  // Filtered appointments based on search term and selected date
  const filteredAppointments = appointments
    .filter(appointment => {
      const matchesDate = selectedDate ? appointment.appointmentDate === selectedDate : true;
      const matchesName = appointment.name.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesDate && matchesName;
    })
    .slice(0, lazyLoadCount);

  return (
    <div className="appointment">
      <h2>All Appointments</h2>
      <div className="filters">
        {/* Input for filtering appointments by name */}
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* Input for filtering appointments by date */}
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </div>
      {loading ? (
        // Display a loading spinner if the data is being loaded
        <div className="spinner">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <>
          {/* Table displaying the filtered appointments */}
          <table className="appointment-table">
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Name</th>
                <th>Email</th>
                <th>Appointment Date</th>
                <th>Appointment Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredAppointments.map((appointment) => (
                <tr key={appointment.id}>
                  <td>{appointment.name}</td>
                  <td>{appointment.email}</td>
                  <td>{appointment.appointmentDate}</td>
                  <td>{appointment.appointmentTime}</td>
                  <td>{appointment.status}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Button to load more appointments if there are more */}
          {filteredAppointments.length < appointments.length && (
            <button className='load-more-button' onClick={loadMoreAppointments}>Load More</button>
          )}
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default Appointment;
