// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background-color: #f7f7f7;
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 50px auto;
  }
  
  .settings__header {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .settings__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .settings__label {
    margin-bottom: 10px;
    color: #555;
    font-size: 18px;
  }
  
  .settings__input {
    padding: 12px;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 18px;
    width: 100%;
  }
  
  .settings__btn {
    padding: 12px 20px;
    background-color: #999;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  
  .settings__btn:hover {
    background-color: #444;
  }
  
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Settings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;IACvC,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf","sourcesContent":[".settings {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 40px;\n    background-color: #f7f7f7;\n    border-radius: 12px;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);\n    max-width: 600px;\n    margin: 50px auto;\n  }\n  \n  .settings__header {\n    font-size: 32px;\n    margin-bottom: 30px;\n    color: #333;\n  }\n  \n  .settings__form {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .settings__label {\n    margin-bottom: 10px;\n    color: #555;\n    font-size: 18px;\n  }\n  \n  .settings__input {\n    padding: 12px;\n    margin-bottom: 25px;\n    border: 1px solid #ccc;\n    border-radius: 6px;\n    font-size: 18px;\n    width: 100%;\n  }\n  \n  .settings__btn {\n    padding: 12px 20px;\n    background-color: #999;\n    color: #fff;\n    border: none;\n    border-radius: 6px;\n    cursor: pointer;\n    font-size: 18px;\n    transition: background-color 0.3s;\n  }\n  \n  .settings__btn:hover {\n    background-color: #444;\n  }\n  \n  .spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
